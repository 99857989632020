/**
 * Created by dev on 06.06.17.
 */
var yaCounterId = '44670661';

window.tagManager = function() {
  return this;
};

// Использовать заглушку для тестирования
tagManager.useDummy = false;

tagManager._yaDummy = function() {
  return this;
};

tagManager._yaDummy.reachGoal = function(target) {
  argilla.message('yandex цель '+ target);
};

tagManager._gaDummy = function() {
  argilla.message(Array.prototype.slice.call(arguments));
};

tagManager.getYa = function() {
  if( tagManager.useDummy ) {
    return tagManager._yaDummy;
  }
  else
  {
    if(typeof(window['yaCounter' + yaCounterId]) === "undefined")
    {
      argilla.error('Ошибка, не найден объект yaCounter' + yaCounterId + '. Будет использована отладочная заглушка');
      return tagManager._yaDummy;
    }
  }

  return window['yaCounter' + yaCounterId];
};

tagManager.getGa = function() {
  if( tagManager.useDummy )
    return window.tagManager['_gaDummy'].apply(this, Array.prototype.slice.call(arguments));
  else
  {
    if(typeof(window.ga) === "undefined") {
      argilla.error('Ошибка, не найден объект ga. Будет использована отладочная заглушка');
      return window.tagManager['_gaDummy'].apply(this, Array.prototype.slice.call(arguments));
    }
  }

 return window['ga'].apply(this, Array.prototype.slice.call(arguments));
};

tagManager.pushData = function(data) {
  window.dataLayer.push(data);
  argilla.message(data);
};

tagManager.triggerList = {};

tagManager.createTrigger = function(trigger) {
  tagManager.triggerList[trigger] = trigger;
};

tagManager.isSetTrigger = function(trigger) {
  return typeof(tagManager.triggerList[trigger]) !== "undefined";
};

tagManager.freeTrigger = function(trigger) {
  if(tagManager.isSetTrigger)
    delete tagManager.triggerList[trigger];
};

tagManager.addToBasketFromCatalog = function() {
  this.getYa().reachGoal('Buyvitrine');
  //argilla.message('Добавил товар в корзину на разводной');
};

tagManager.addToBasketFromAllPage = function() {
  this.getGa('send', 'event', 'Карточка товара', 'Добавил в корзину');
};

tagManager.addToBasketFromOtherPage = function() {
  this.getYa().reachGoal('Buyclick');
};

tagManager.addToBasketFromCard = function() {
  this.getYa().reachGoal('Buycart');
  //argilla.message('Добавил товар в корзину в карточке');
};

tagManager.addToBasketFromFavorite = function() {
  this.getYa().reachGoal('Buylike');
  //argilla.message('Добавил товар в корзину из избранного');
};

tagManager.submitOrder = function() {
  this.getYa().reachGoal('OrderSend');
  this.getGa('send', 'event', 'Корзина', 'Отправил');
  //argilla.message('Нажал кнопку купить в корзине');
};

tagManager.showOneClick = function() {
  this.getYa().reachGoal('OneClickOpen');
  this.getGa('send', 'event', 'Быстрый заказ', 'Вызов');

  this.createTrigger('showOneClick');
};

tagManager.closeOneClick = function() {
  if( !this.isSetTrigger('showOneClick') )
    return;

  this.getYa().reachGoal('OneClickClose');
};

tagManager.submitOneClick = function() {
  this.getYa().reachGoal('OneClickSend');
  this.getGa('send', 'event', 'Быстрый заказ', 'Отправил');

  this.freeTrigger('showOneClick')
};

tagManager.showCallback = function() {
  this.getYa().reachGoal('CallOpen');
  this.getGa('send', 'event', 'Заказ звонка', 'Вызов');

  this.createTrigger('showCallback');
};

tagManager.closeCallback = function() {
  if( !this.isSetTrigger('showCallback') )
    return;

  this.getYa().reachGoal('CallClose');
};

tagManager.submitCallback = function() {
  this.getYa().reachGoal('CallSend');
  this.getGa('send', 'event', 'Заказ звонка', 'Отправил');
  this.freeTrigger('showCallback');
};

tagManager.showProductRequest = function() {
  this.getYa().reachGoal('OpenBuy');
  this.getGa('send', 'event', 'Заказ товара не в наличии', 'Открыл');
};

tagManager.submitProductRequest = function() {
  this.getYa().reachGoal('SendBuy');
  this.getGa('send', 'event', 'Заказ товара не в наличии', 'Отправил');
};

tagManager.addToFavorite = function() {
  this.getYa().reachGoal('ClickFavorites');
  //argilla.message('Добавил товар в избранное');
};

tagManager.shareFromCard = function() {
  this.getYa().reachGoal('ClickShared');
  //argilla.message('Нажал поделиться в карточке');
};

tagManager.search = function(text) {
  //argilla.message('Поиск ' + escape(text));
};

tagManager.ecommerce = function () {
  return this;
};

tagManager.ecommerce.purchase = function(order) {

  //Yandex ecommerce
  tagManager.pushData({
    "ecommerce": {
      "currencyCode": "RUB",
      "purchase": {
        "actionField": {
          "id" : order.id
        },
        "products": order.products.reduce(function(items, item, i, arr) {
          items.push({
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: item.quantity
          });
          return items;
        }, [])
      }
    }
  });

  window.ecommerce.purchase({
      "actionField": {
        "id" : order.id
      },
      "products": order.products.reduce(function(items, item, i, arr) {
        items.push({
          id: item.id,
          name: item.name,
          price: item.price,
          quantity: item.quantity
        });
        return items;
      }, [])
  });

  //Google ecommerce
  var transactionId = order.id;
  tagManager.getGa('require', 'ecommerce');
  tagManager.getGa('ecommerce:addTransaction', {
    'id': transactionId,
    'revenue': order.sum
  });

  for(var i in order.products) {
    if( !order.products.hasOwnProperty(i) )
      continue;

    tagManager.getGa('ecommerce:addItem', {
      'id': transactionId,
      'name': order.products[i].name,
      'price': order.products[i].price,
      'quantity':order.products[i].quantity
    });
  }
  tagManager.getGa('ecommerce:send');

};
