import Teaser from './modules/teaser.js';
import mediaQueries from './media-queries-config.json';

// Const
// -----

window.SMALL_MOBILE_WIDTH = mediaQueries.mobile.small;
window.MOBILE_WIDTH = mediaQueries.mobile.portrait;
window.LANDSCAPE_MOBILE_WIDTH = mediaQueries.mobile.landscape;
window.PORTRAIT_TABLET_WIDTH = mediaQueries.tablet.portrait;
window.TABLET_WIDTH = mediaQueries.tablet.landscape;
window.SMALL_NOTEBOOK_WIDTH = mediaQueries.notebook.small;
window.NOTEBOOK_WIDTH = mediaQueries.notebook.normal;

window.HEADER_HEIGHT = $('.header').height();

// selectors
window.$WINDOW = $(window);
window.$DOCUMENT = $(document);
window.$HTML = $(document.documentElement);
window.$BODY = $(document.body);

// tosrus default settings
window.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },

  keys: {
    prev: 37,
    next: 39,
    close: 27
  },

  wrapper: {
    onClick: 'close'
  }
};


// Helpers
// -------

window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
window.WINDOW_HEIGHT = $WINDOW.height();
$WINDOW.resize(function() {
  WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
  WINDOW_HEIGHT = $WINDOW.height();
});

window.IS_DESKTOP_WIDTH = function() {
  return WINDOW_WIDTH > NOTEBOOK_WIDTH;
};
window.IS_NOTEBOOK_WIDTH = function() {
  return ( WINDOW_WIDTH > SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= NOTEBOOK_WIDTH );
};
window.IS_SMALL_NOTEBOOK_WIDTH = function() {
  return ( WINDOW_WIDTH > TABLET_WIDTH && WINDOW_WIDTH <= SMALL_NOTEBOOK_WIDTH );
};
window.IS_TABLET_WIDTH = function() {
  return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= TABLET_WIDTH );
};
window.IS_MOBILE_WIDTH = function() {
  return WINDOW_WIDTH <= MOBILE_WIDTH;
};
window.IS_LANDSCAPE_MOBILE_WIDTH = function() {
  return WINDOW_WIDTH <= LANDSCAPE_MOBILE_WIDTH;
};
window.IS_SMALL_MOBILE_WIDTH = function() {
  return WINDOW_WIDTH <= SMALL_MOBILE_WIDTH;
};
window.IS_TOUCH_DEVICE = 'ontouchstart' in document;


// Masked input
// ------------

// Phone
// $('input[type="tel"]').mask('+7 (999) 999-99-99', {
//   autoclear: false
// });

$(function() {
  $('input[type="tel"]').each(function() {

    const $input = $(this);
    const defaultPlaceholder = $input.attr('placeholder') || '';
    const defaultPlaceholderMask = '+7 (___) ___-__-__';
    const phoneMask = '+7 (000) 000-00-00';
    let pasted = false;

    $input.on('focus', function() {
      $input.attr('placeholder', defaultPlaceholderMask);
    }).on('blur', function() {
      $input.attr('placeholder', defaultPlaceholder);
    });

    $input.on('paste drop', function() {
      let isEmpty = !$input.val();
      let element = $input.get(0);
      let selectionLength = element.selectionEnd - element.selectionStart;

      if (!isEmpty && $input.val().length !== selectionLength) {
        return;
      }

      pasted = true;
    });

    $input.on('input', function(e) {
      let value = $input.val();

      if (value.startsWith('8')) {
        if (pasted && value.length !== 11) {
          pasted = false;
          return;
        }

        pasted = false;

        e.stopPropagation();

        value = value.replace('8', '+7');
        $input.val(value);
      }
    });

    $input.mask(phoneMask);
  });
});

if (IS_DESKTOP) {
  $('input[type="date"]').attr('type', 'text');

  // Date
  $('.js-date-mask').mask('99/99/9999', {
    placeholder: 'дд.мм.гггг',
    autoclear: false
  });

  // Time
  $('.js-time-mask').mask('99:99', {
    placeholder: 'чч:мм',
    autoclear: false
  });
}


// Textarea autosize
// -----------------

$(function() {
  autosize($('textarea'));
});


// Rating input
// ------------

$('.js-rating-input .star').click(function() {
  const $this = $(this);
  const $stars = $this.parent().find('.star');
  const rating = $this.index() + 1;
  const $inp = $this.parent().find('.inp');

  $inp.val(rating);
  $stars.removeClass('star_filled').slice(0, rating).addClass('star_filled');
});


// Spinner
// -------

$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function() {
  var $spinnerControl = $(this),
      $input = $spinnerControl.siblings('.inp'),
      value = parseInt( $input.val(), 10 ),
      step = $input.data('step') ? $input.data('step') : 1,
      mayBeZero = $input.data('zero') ? $input.data('zero') : false,
      incTimeout, incInterval, decTimeout, decInterval;

  $spinnerControl
    .on('mouseup.js-spinner', clearAll)
    .on('mouseleave.js-spinner', $spinnerControl, clearAll);

  if ( $spinnerControl.hasClass('js-spinner-down') ) {
    decVal(); decTimeout = setTimeout(function() {
      decInterval = setInterval(decVal, 70);
    }, 300);
  }

  if ( $spinnerControl.hasClass('js-spinner-up') ) {
    incVal(); incTimeout = setTimeout(function() {
      incInterval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ( $.isMouseLoaderActive() ) return;

    $input.val(value + step).change();
  }

  function decVal() {
    if ( $.isMouseLoaderActive() ) return;

    if ( mayBeZero ) {
      if ( value >= step ) {
        $input.val(value - step).change();
      }
    } else {
      if ( value > step ) {
        $input.val(value - step).change();
      }
    }
  }

  function clearAll() {
    clearTimeout(decTimeout); clearInterval(decInterval);
    clearTimeout(incTimeout); clearInterval(incInterval);
  }
});

$DOCUMENT.on('keydown', '.js-spinner .inp', function(e) {
  var $input = $(this);

  if ( e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 ||
    (e.keyCode == 65 && e.ctrlKey === true) ||
    (e.keyCode >= 35 && e.keyCode <= 39)) {
    return;
  } else {
    if ( (e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105 ) ) {
      e.preventDefault();
    }
  }
});

$DOCUMENT.on('keyup paste', '.js-spinner .inp', function(e) {
  var $input = $(this),
      mayBeZero = $input.data('zero') ? $input.data('zero') : false;

  if ( !mayBeZero && $input.val() == 0 ) {
    $input.val(1);
  }
});

//В карточке при изменени количества пересчитываем цены
$DOCUMENT.on('change', '.js-spinner .amount-basket', function(e) {

  var findDiscount = function (amount) {
    var discountList = $('.product-card--price .price').data('discount-data');
    for(var i in discountList) {
      var from = discountList[i].from;
      var to = discountList[i].to == "" ? 99999999 : discountList[i].to;

      if( amount >= from && amount <= to )
        return discountList[i].discount;
    }

    return 0;
  };

  var amount = $(this).val();
  var $price = $('.product-card--price .price');
  var $priceOld = $('.product-card--price .old-price');
  var $score = $('.product-card--score');
  var $economy = $('.product-card--economy');
  var discount = findDiscount(amount);

  var price = $price.data('price');
  if( discount > 0 && $priceOld.length )
  {
    price = $priceOld.data('price') - Math.ceil($priceOld.data('price') * discount / 100);
    $economy.text('-' + number_format(discount) + '%');
  }
  $price.text(number_format(price * amount));


  if($priceOld.length)
    $priceOld.text(number_format($priceOld.data('price') * amount));

  if($score.length) {
    var score = $score.data('score') * amount;
    $score.text('+' + number_format(score) + ' ' + argilla.plural(score, ['балл', 'балла', 'баллов']));
  }
});


// Overlay loader
// --------------

// open popup
$DOCUMENT.on('click.overlay-open', '.js-overlay', function(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, {
    node: $popup,
    hideSelector: '.js-close-popup',
    onShow : function() { argilla.event.raise('show.popup', {selector: $popup});},
    onHide : function() { argilla.event.raise('hide.popup', {selector: $popup});}
  });
});

// autofocus
$DOCUMENT.on('overlayLoaderShow', function(e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

// popups with ajax content
$DOCUMENT.on('click.ajax-overlay-open', '.js-ajax-overlay', function(e) {
  e.preventDefault();

  var $this = $(this);
  var $node = $($this.attr('href'));

  $.overlayLoader(true, {
    node: $node,
    hideSelector: '.js-close-popup',
    onShow : function() { argilla.event.raise('show.popup', {selector: $node});},
    onHide : function() { argilla.event.raise('hide.popup', {selector: $node});}
  });

  var params = $this.data('ajax-params') === undefined ? '' : $this.data('ajax-params');
  $.ajax({
    type: $this.data('type'),
    url: $this.data('ajax-url'),
    data: _parseAjaxOverlayParams(params),
    timeout: 10000,

    success: function(data) {
      $node.find('.js-ajax-data').html(data);
    },

    error: function(data) {
      var $errorText = $('Ошибка. Попробуйте открыть окно снова.');
      $node.find('.js-ajax-data').html( $errorText );
    }
  });
});

function _parseAjaxOverlayParams(str) {
  var obj = {};
  var array = str.split('&');

  array.forEach(function(value) {
    var _a = value.split('=');
    obj[_a[0]] = _a[1];
  });

  return obj;
}


// Selectric
// ---------

// init selectric
$DOCUMENT.on('initSelectric yiiListViewUpdated', function() {
  $('select').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
}).trigger('initSelectric');


// Listview
// --------

$DOCUMENT.on('yiiListViewUpdated', function() {
  $('.js-lazy-pic').unveil(200);

  $('.js-gallery').each(function() {
    $(this).find('.js-gallery-item').tosrus(TOSRUS_DEFAULTS);
  });
});

$BODY.on('click', '.bottom-sorting .page a, .next-page-placeholder', function() {
  // var $listView = $('.list-view');

  // $listView.addClass('bottom');
  $WINDOW.scrollTo(0, 200);
});


// Checkboxes
// ----------

$('.js-checkbox input').on('change initCheckboxes', function() {
  var $inp = $(this),
      $label = $inp.closest('.js-checkbox');

  if ( $inp.prop('checked') ) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
}).trigger('initCheckboxes');

$BODY.on('mouseenter', '.js-wine-checkbox', function() {
  var $this = $(this);
  $this.closest('.js-colors-group').find('.js-color-text').text( $this.data('color') );
});
$BODY.on('mouseleave', '.js-wine-checkbox', function() {
  var $this = $(this);
  $this.closest('.js-colors-group').find('.js-color-text').empty();
});


// Radio buttons
// ----------

$('.js-radio input').on('change initRadio', function() {
  var $inp = $(this),
      $group = $('[name="' + $inp.attr('name') + '"]'),
      $labels = $group.closest('.js-radio'),
      $selectedItem = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selectedItem.addClass('is-checked');
}).trigger('initRadio');


// Scroll to
// ---------

$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function(e) {
  e.preventDefault();

  var $lnk = $(this);
  var $elemToScroll = $($lnk.attr('href'));
  var speed = $lnk.data('speed') || 150;
  var offset = $lnk.data('offset') || 0;

  $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
});


// Menus
// -----

(function() {
  var $menus = $('.js-menu');

  if (IS_DESKTOP) {
    $menus.on('mouseenter.js-menu', 'li', function() {
      var $this = $(this);
      clearTimeout($this.data('hoverTimeout'));
      $this.addClass('is-hovered');
    });

    $menus.on('mouseleave.js-menu', 'li', function() {
      var $this = $(this);
      $this.data('hoverTimeout', setTimeout(function() {
        $this.removeClass('is-hovered');
      }, 200));
    });
  }

  if (IS_MOBILE) {
    $menus.on('click.js-m-menu', 'a', function(e) {
      e.preventDefault();

      var $anchor = $(this);
      var $parent = $anchor.parent();

      var hasDropdown = $parent.hasClass('has-dropdown');
      var isHovered = $parent.hasClass('is-hovered');
      var isCatalogueMenu = $parent.closest('.catalogue-menu').length;

      $parent.siblings().removeClass('is-hovered');

      if (!hasDropdown) {
        if ($anchor.attr('href')) {
          location.href = $anchor.attr('href');
        }
      } else {
        if (isHovered) {
          if ($anchor.attr('href')) {
            location.href = $anchor.attr('href');
          }
        } else {
          $parent.addClass('is-hovered');
        }

        if (isCatalogueMenu) {
          $parent.closest('.catalogue-menu').addClass('second-level-opened');
        }
      }
    });

    $menus.on('click.menu-toggle', '.toggler', function(e) {
      e.preventDefault();
      e.stopPropagation();

      $(this).closest('.has-dropdown').toggleClass('is-hovered');
    });

    $menus.on('click.menu-section-toggle', '.js-section-toggler > span', function() {
      $(this).closest('.js-section-toggler').toggleClass('is-collapsed');
    });

    $('.js-mobile-back').click(function() {
      $('.mobile-menu .is-hovered').removeClass('is-hovered');
      $('.mobile-menu .catalogue-menu').removeClass('second-level-opened');
    });
  }

  // Mobile menu
  $('.js-toggle-menu').click(function(e) {
    e.preventDefault();

    $BODY.toggleClass('menu-is-active');

    $('.mobile-menu .is-hovered').removeClass('is-hovered');
    $('.mobile-menu .catalogue-menu').removeClass('second-level-opened');
  });
}());


// Tabs
// ----

$('.js-tabs .tabs-nav li a').click(function(e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $( $this.attr('href') );

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.js-tabs').find('.tabs-panel').stop(true, true).hide();
  $panel.fadeIn().css('display', 'block');
  $panel.find('.js-lazy-pic').unveil(200);
});

$(function() {
  $('.js-tabs .tabs-nav li:first a').click().trigger('tabsInit');
});


// Galleries
// ---------

// init tosrus static gallery
$('.js-gallery').each(function() {
  $(this).find('.js-gallery-item').tosrus(TOSRUS_DEFAULTS);
});

// init tosrus static gallery for single image on phones
if ( IS_SMALL_MOBILE_WIDTH() ) {
  $('.js-mobile-zoom').tosrus(TOSRUS_DEFAULTS);
} else {
  $('.js-mobile-zoom').click(function(e) {
    e.preventDefault();
  });
}

$('.js-product-carousel').tosrus({
  effect: 'slide',
  slides: {
    visible: 3
  },
  buttons: {
    prev: $('.js-gallery-prev'),
    next: $('.js-gallery-next')
  }
});
if ( $('.js-product-carousel .item').length > 3 ) {
  $('.js-gallery-next, .js-gallery-prev').show();
}


$('.js-product-carousel-blog').each(function(){
  var $this = $(this);
  $this.data('html-backup', $this.html());
  $this.tosrus({
    slides: {
      visible: CalcVisibleSlides()
    }
  });
});

TosCarouselWrapperWidthFix();

function CalcVisibleSlides(){
  if (IS_DESKTOP_WIDTH()) return 4;
  if (IS_NOTEBOOK_WIDTH()) return 4;
  if (IS_SMALL_NOTEBOOK_WIDTH()) return 3;
  if (IS_TABLET_WIDTH()) return 3;
  if (IS_SMALL_MOBILE_WIDTH()) return 1;
  if (IS_MOBILE_WIDTH()) return 2;
  return 3;
};

$WINDOW.on('resize', function(){
  $('.js-product-carousel-blog').each(function(){
    var $this = $(this);
    $this.empty();
    $this.html( $this.data('html-backup') );
    $(this).tosrus({
      slides: {
        visible: CalcVisibleSlides()
      }
    });
  });
  TosCarouselWrapperWidthFix();
});

function TosCarouselWrapperWidthFix(){
  if (WINDOW_WIDTH < 1145 ){ $('.js-product-carousel-blog > .tos-wrapper').width('calc(100vw - 40px'); }
  else { $('.js-product-carousel-blog > .tos-wrapper').width('100%');}};



// Rotators
// --------

$('.js-slideshow').each(function() {
  var $this = $(this);

  var tos = $this.tosrus({
    effect: 'slide',
    slides: {
      visible: 1
    },
    autoplay: {
      play: true,
      timeout: 7500
    },
    infinite: true,
    pagination: {
      add: true
    }
  });
});

$('.js-menu-slider').each(function() {
  var $this = $(this);

  var tos = $this.tosrus({
    effect: 'slide',
    slides: {
      visible: 1
    },
    infinite: true,
    buttons: {
      next: true,
      prev: true
    }
  });
});

$('.js-text-slider').each(function() {
  var $this = $(this);

  var tos = $this.tosrus({
    effect: 'slide',
    slides: {
      visible: 1
    },
    infinite: true,
    buttons: {
      next: $('.js-text-slider-next'),
      prev: $('.js-text-slider-prev')
    }
  });
});


// Scrolling to top
// ----------------

if ( !IS_MOBILE_WIDTH() ) {
  var goTopBtn = $('<div class="go-top-btn"></div>');
  goTopBtn.click(function() {
    $WINDOW.scrollTo(0, 200);
  });
  $WINDOW.scroll(function() {
    var scrollTop = $WINDOW.scrollTop();
    if ( scrollTop > 0 ) {
      goTopBtn.addClass('visible');
    } else {
      goTopBtn.removeClass('visible');
    }
  });
  $BODY.append( goTopBtn );
}


// Filters toggle
// --------------

$BODY.on('click', '.js-toggle-filter-subitems', function() {
  $(this).closest('.has-items').toggleClass('is-expanded');
});

$BODY.on('click', '.js-toggle-long-list', function() {
  if ( IS_MOBILE_WIDTH() ) {
    $(this).closest('.filters-group--body').toggleClass('is-expanded');
  } else {
    var $this = $(this),
        $popup = $('#more-filters-popup'),
        $popupCaption = $popup.find('.filter--caption'),
        $popupBody = $popup.find('.filter--values'),
        $filter = $popup.find('.filter--alphabet').empty();

    $popupCaption.text( $this.closest('.filters--group').find('.filters-group--caption').text() );
    $popupBody.empty().append( $this.closest('.filters-group--body').clone() );

    $popupBody.find('.checkbox-item').sort(function(a, b) {
      var a = $(a).text(),
          b = $(b).text();

      if ( a > b ) {
        return 1;
      }

      if ( a < b ) {
        return -1;
      }

      return 0;
    }).detach().appendTo($popupBody.find('.filters-group--body'));

    var setPopupBodyHeight = function(popupIsHidden) {
      if ( popupIsHidden ) $popup.show();
      var $lastItem = $popupBody.find('.checkbox-item:not(.long-list-toggler):visible:last');
      $popupBody.css('height', 'auto').css('height', $popupBody.height() / 4);
      while ( $lastItem.position().left > $popupBody.width() ) {
        $popupBody.height( $popupBody.height() + $lastItem.height() );
      }
      if ( popupIsHidden ) $popup.hide();
    }

    if ( $popupBody.find('.checkbox-item').length <= 30 ) {
      $filter.hide();
    } else {
      $filter.show();
      var letters = [];
      $popupBody.find('.checkbox-item:not(.long-list-toggler)').each(function() {
        var $this = $(this),
            symbol = $this.find('.checkbox').text()[0].toLowerCase();
        if ( letters.indexOf( symbol ) == -1 ) {
          letters.push( symbol );
        }
        $this.data('letter', symbol);
      });

      letters.sort().forEach(function(symbol) {
        $filter.append( $('<a />').attr('href', symbol).text( symbol ) );
      });

      $filter.append( $('<a href="all">Все</a>') ).prepend('Отфильтровать ');

      $filter.find('a').click(function(e) {
        e.preventDefault();

        var $letter = $(this);

        $letter.addClass('is-active').siblings().removeClass('is-active');

        if ( $letter.attr('href') != 'all' ) {
          $popupBody.find('.checkbox-item:not(.long-list-toggler)').stop(true, true).hide().each(function() {
            var $this = $(this);
            if ( $this.data('letter') == $letter.attr('href') ) {
              $this.stop(true, true).fadeIn();
            }
          });
        } else {
          $popupBody.find('.checkbox-item:not(.long-list-toggler)').stop(true, true).fadeIn();
        }

        setPopupBodyHeight(false);
      });
    }

    $.overlayLoader(true, {
      node: $popup,
      hideSelector: '.js-close-popup',
      onStart: function() {
        setPopupBodyHeight(true);
      }
    });
  }
});

$BODY.on('click', '.js-chose-price', function(e) {
  e.preventDefault();

  $('#price-slider-block').stop(true, true).slideToggle();
});


// Fixed filters
// -------------

var asideScrollbar;
(function() {
  if ( $WINDOW.width() <= PORTRAIT_TABLET_WIDTH ) return;

  var $aside = $('.js-scrollable-aside'),
      $productList = $('#main #product_list');

  if ( !$aside.length || !$productList.length ) return;

  var offset = $aside.offset().top - 20,
      endOffset = $productList.offset().top + $productList.height() - WINDOW_HEIGHT;

  var checkAsidePos = function() {
    var scrollTop = $WINDOW.scrollTop();

    if ( scrollTop >= offset ) {
      if ( !$aside.hasClass('is-fixed') ) {
        asideScrollbar = $aside.addClass('is-fixed').perfectScrollbar();
      } else {
        if ( scrollTop >= endOffset ) {
          $aside.css('height', 'calc(100vh - ' + -(endOffset - scrollTop - 20) + 'px)');
        } else {
          $aside.attr('style', '');
        }
      }
    } else {
      $aside.removeClass('is-fixed').css('height', 'auto');
      if ( asideScrollbar && asideScrollbar.pr ) {
        asideScrollbar.perfectScrollbar('destroy');
      }
    }
  }

  $WINDOW.on('asideInit', function() {
    offset = $aside.offset().top - 20;
    endOffset = $productList.offset().top + $productList.height() - WINDOW_HEIGHT;
  });
  $DOCUMENT.on('yiiListViewUpdated', function() {
    offset = $aside.offset().top - 20;
    $productList = $('#main #product_list');
    endOffset = $productList.offset().top + $productList.height() - WINDOW_HEIGHT;
    checkAsidePos();
  });
  $WINDOW.on('scroll asideInit', function() {
    checkAsidePos();
  }).trigger('asideInit');
})();

$('body').bind('focusin focus', function(e){
  e.preventDefault();
})

// Toggle extra params
// -------------------

$BODY.on('click', '.js-toggle-extra-group', function() {
  $(this).closest('.group').toggleClass('is-expanded').siblings().toggle();
})


// Toggle params list in card
// --------------------------
$('.js-toggle-choice-block').click(function() {
  $(this).closest('.js-choice-block').toggleClass('is-expanded');
});


// Fixed basket aside
// ------------------

$('.js-basket-aside').each(function() {
  if ( !IS_DESKTOP_WIDTH() ) return;

  var $aside = $(this),
      asideOffset = $aside.offset().top;

  $WINDOW.scroll(function() {
    var scrollTop = $WINDOW.scrollTop();

    if ( scrollTop + 20 >= asideOffset ) {
      $aside.addClass('is-fixed');
    } else {
      $aside.removeClass('is-fixed');
    }
  });
});


// Pictures lazy load
// ------------------

$DOCUMENT.on('iasRender tabsInit', function() {
  setTimeout(function() {
    $('.js-lazy-pic').unveil(200);
  }, 100);
});

$('.js-lazy-pic').unveil(200);

$('.wine-of-the-day .js-lazy-pic').trigger('unveil');

if ( IS_MOBILE_WIDTH() ) {
  $('.js-lazy-pic').trigger('unveil');
}


// History table collapse
// ----------------------

$('.js-toggle-history').click(function() {
  $(this).closest('.js-order-header').toggleClass('is-collapsed');
});


// Mobile spoilers
// ---------------

$('.js-mobile-spoiler-anchor').click(function() {
  if ( !IS_MOBILE_WIDTH() ) return;

  $(this).closest('.js-mobile-spoiler').toggleClass('is-collapsed');
});


// Spoilers
// --------

$('.js-toggle-spoiler').click(function() {
  $(this).closest('.js-spoiler').toggleClass('is-expanded');
});


// Description toggle
// ------------------

var $description = $('.product-card--description');
if ( !IS_MOBILE_WIDTH() && $description.length ) {
  var setColumnHeight = function() {
    var $column = $('.product-card--third-column'),
        initialHeight = $column.height() - ($description.offset().top - $column.offset().top);

    $description.css('maxHeight', initialHeight);

    if ( $description.find('.inner').height() > $description.innerHeight() ) {
      $description.addClass('is-cropped');
    }
  }

  $description.find('.js-toggle-description').click(function() {
    $description.toggleClass('is-expanded')
  });

  // setColumnHeight();

  $('.product-card--second-column').imagesLoaded().progress(function() {
    setColumnHeight();
  });
}

// Contacts page slider
// --------------------

if ( $WINDOW.width() > TABLET_WIDTH ) {
  $('.js-contacts-gallery').each(function() {
    const $this = $(this);

    $this.tosrus({
      effect: 'slide',
      slides: {
        visible: 3
      },
      zoom: true,
      buttons: {
        prev: $('.contact__gallery-prev'),
        next: $('.contact__gallery-next')
      }
    });
  });
} else if ( SMALL_MOBILE_WIDTH < $WINDOW.width() <= TABLET_WIDTH ) {
  $('.js-contacts-gallery').each(function() {
    const $this = $(this);

    $this.tosrus({
      effect: 'slide',
      slides: {
        visible: 2
      },
      zoom: true,
      buttons: {
        prev: $('.contact__gallery-prev'),
        next: $('.contact__gallery-next')
      }
    });
  });
} else if ( $WINDOW.width() <= SMALL_MOBILE_WIDTH ) {
  $('.js-contacts-gallery').each(function() {
    const $this = $(this);

    $this.tosrus({
      effect: 'slide',
      slides: {
        visible: 1
      },
      zoom: true,
      buttons: {
        prev: $('.contact__gallery-prev'),
        next: $('.contact__gallery-next')
      }
    });
  });
};

$('.contact__gallery-prev').on('click', function(e) {
  e.preventDefault();
})

$('.contact__gallery-next').on('click', function(e) {
  e.preventDefault();
})
// --------------------

// Homepage reviews slider
// --------------------

if ( $WINDOW.width() > MOBILE_WIDTH ) {
  $('.js-reviews-slider').each(function() {
    const $this = $(this);

    $this.tosrus({
      effect: 'slide',
      slides: {
        visible: 3
      },
      buttons: {
        prev: $('.reviews-slider-prev'),
        next: $('.reviews-slider-next')
      }
    });
  });
} else if ( $WINDOW.width() <= MOBILE_WIDTH ) {
  $('.js-reviews-slider').each(function() {
    const $this = $(this);

    $this.tosrus({
      effect: 'slide',
      slides: {
        visible: 1
      },
      buttons: {
        prev: $('.reviews-slider-prev'),
        next: $('.reviews-slider-next')
      }
    });
  });
}

$('.reviews-slider-prev').on('click', function(e) {
  e.preventDefault();
})

$('.reviews-slider-next').on('click', function(e) {
  e.preventDefault();
})
// --------------------


// Mobile phone popup
// ------------------

$('.js-mobile-phone').click(function(e) {
  if ( IS_DESKTOP_WIDTH() ) return;

  e.preventDefault();

  $(this).closest('.header--phones').toggleClass('is-active');
  $('#mobile-phones').stop(true, true).fadeToggle();
});

$('.js-close-phones-popup, .js-mobile-callback-link').click(function() {
  $('#mobile-phones').stop(true, true).fadeOut().closest('.header--phones').removeClass('is-active');
});


// Mobile search
// -------------

$('.js-mobile-search-link').click(function(e) {
  e.preventDefault();

  $('.header--search').stop(true, true).fadeIn();
});

$('.js-close-mobile-search').click(function() {
  $('.header--search').stop(true, true).fadeOut();
});


// Search reset
// ------------

$('.js-clear-search').click(function() {
  $(this).siblings('.inp').val('').trigger('change');
});

$('.js-clear-search').each(function() {
  var $btn = $(this);
  $btn.siblings('.inp').on('change keyup', function() {
    var $inp = $(this);
    if ( $inp.val().length == 0 ) {
      $btn.addClass('hidden');
    } else {
      $btn.removeClass('hidden');
    }
  }).trigger('change');
});


// Age confirm popup
// -----------------

var STORAGE_KEY = 'IS_AGE_CONFIRMED',
    isAgeConfirmed = localStorage.getItem( STORAGE_KEY ),
    $agePopup = $('#age-popup');

$('.js-confirm-age-popup').click(function() {
  $agePopup.addClass('is-confirmed');
  $.overlayLoader(false, $agePopup);
});

if ( !isAgeConfirmed ) {
  showAgePopup();
}

function showAgePopup() {
  $.overlayLoader(true, {
    node: $agePopup,
    hideSelector: '.js-close-popup',
    onHide: function() {
      if ( !$agePopup.hasClass('is-confirmed') ) {
        $.overlayLoader(true, {
          node: $('#age-popup-warning'),
          hideSelector: '.js-close-popup',
          onHide: function() {
            showAgePopup();
          }
        });
      } else {
        localStorage.setItem( STORAGE_KEY, true );
      }
    }
  });
}


// Catalogue banner
// ----------------

let teaser = new Teaser({
  selector: '.js-catalogue-teaser',
  daysToHide: 7,
});


// Expand vitrine text on mobiles
// ------------------------------

$('.vitrine-text').each(function() {
  const $text = $(this);

  if ( $text.height() > 60 ) {
    $text.addClass('is-collapsable');
    $text.click(() => {
      $text.toggleClass('is-expanded');
    });
  }
});


// Expand vintage list
// -------------------

$('.js-vintage-list-toggle').click(function(e) {
  e.preventDefault();
  $(this).closest('.vintage-section--list').toggleClass('is-expanded');
});

$('.js-toggle-vintage-section-open').click(function() {
  $('.vintage-section').removeClass('is-collapsed');
});


// Sort vintage list
// -----------------

$('.js-sort-table-by-column').click(function() {
  const $trigger = $(this);
  const column = $trigger.closest('div').index() + 1;
  const $list = $('.vintage-section--list');
  const $items = $list.find('.vintage-section--item');
  const sortDirection = !$(this).hasClass('asc') ? 1 : -1;

  $trigger.toggleClass('asc');

  const $sortedItems = $items.sort(function(a, b) {
    var aValue = $(a).find(`div:nth-child(${column})`).text();
    var bValue = $(b).find(`div:nth-child(${column})`).text();
    return (aValue < bValue) ? -1 * sortDirection : (aValue > bValue) ? 1 * sortDirection : 0;
  }).sort(function(a, b) {
    var aValue = parseFloat($(a).find(`div:nth-child(${column})`).text().replace(' ', ''));
    var bValue = parseFloat($(b).find(`div:nth-child(${column})`).text().replace(' ', ''));
    return (aValue < bValue) ? -1 * sortDirection : (aValue > bValue) ? 1 * sortDirection : 0;
  });

  $items.remove();
  $list.append($sortedItems);
});

