'use strict';

class ECommerce {

  push(data) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }

  //просмотр товаров в витринах
  impressions(impressions) {
    this.push({
      'ecommerce': {
        'currencyCode': 'RUB',
        'impressions': impressions
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Product Impressions',
      'gtm-ee-event-non-interaction': 'True'
    });
  }

  //клик по товару
  click(click) {
    this.push({
      'ecommerce': {
        'currencyCode': 'RUB',
        'click': click
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Product Clicks',
      'gtm-ee-event-non-interaction': 'False',
    });
  }

  //детальный просмотр товара
  detail(detail) {
    this.push({
      'ecommerce': {
        'currencyCode': 'RUB',
        'detail': detail
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Product Details',
      'gtm-ee-event-non-interaction': 'True',
    });
  }

  //добавление товара в корзину
  add(add) {
    this.push({
      'ecommerce': {
        'currencyCode': 'RUB',
        'add': add,
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Adding a Product to a Shopping Cart',
      'gtm-ee-event-non-interaction': 'False',
    });
  }

  //Удаление товара из корзины
  remove(remove) {
    this.push({
      'ecommerce': {
        'currencyCode': 'RUB',
        'remove': remove
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Removing a Product from a Shopping Cart',
      'gtm-ee-event-non-interaction': 'False',
    });
  }

  checkout(checkout) {
    this.push({
      'ecommerce': {
        'currencyCode': 'RUB',
        'checkout': checkout,
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Checkout Step 1',
      'gtm-ee-event-non-interaction': 'False',
    })
  }

  purchase(purchase) {
    this.push({
      'ecommerce': {
        'currencyCode': 'RUB',
        'purchase': purchase,
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Purchase',
      'gtm-ee-event-non-interaction': 'False',
    });
  }
}
window.ecommerce = new ECommerce();

//---------------------------------------------------------
//Регистрация обработчиков событий для расширенной торговли
//---------------------------------------------------------

//Клик по товару
$(document).on('ecommerce.click', '.js-ecommerce-click', function(event, data) {
  var _ = $.extend({}, $(event.target).data('ecommerce'), data);
  window.ecommerce.click(_);
});

//Просмотр страницы товара
$(document).on('ecommerce.detail', '.js-ecommerce-detail', function(event, data) {
  var _ = $.extend({}, $(event.target).data('ecommerce'), data);
  window.ecommerce.detail(_);
});

//Добавление товара в корзину
$(document).on('ecommerce.add', '.js-ecommerce-add', function(event, data) {
  var _ = $.extend({}, $(event.target).data('ecommerce'), data);
  window.ecommerce.add(_);
});

//Удаление товара из корзины
$(document).on('ecommerce.remove', '.js-ecommerce-remove', function(event, data) {
  var _ = $.extend({}, $(event.target).data('ecommerce'), data);
  window.ecommerce.remove(_);
});

//Переход в корзину
$(document).on('ecommerce.checkout', '.js-ecommerce-checkout', function(event, data) {
  var _ = $.extend({}, $(event.target).data('ecommerce'), data);
  window.ecommerce.checkout(_);
});

//Заказ оформлен в js/src/tag-manager.js:196

//Просмотр витрин товаров (разводные, хиты, аналоги, ...)
(function(){

  //Оформление заказа / переход в корзину
  $('.js-ecommerce-checkout').trigger('ecommerce.checkout');

  //Просмотр карточки товара
  $('.js-ecommerce-detail').trigger('ecommerce.detail');

  //Клик по товару
  $(document).on('click', '.js-ecommerce-click', function(e) {
    $(this).trigger('ecommerce.click');
  });

  var waiting = false,
    endScrollHandle,
    viewed = [],
    clicked = [];

  function isInScreen(current) {
    var scrollTop  = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scrollTop <= current.offset().top && (current.height() + current.offset().top) < (scrollTop + windowHeight)) {
      return true;
    }
    return false;
  }

  function getVisibleProducts() {
    var impressions = [];
    $('.js-ecommerce-impression').filter(function(index) {
      var product = $(this).data('ecommerce');
      return isInScreen($(this)) && viewed.indexOf(product) == -1;
    }).each(function (index) {
      var product = $(this).data('ecommerce');
      impressions.push(product);
      viewed.push(product);
    });
    return impressions;
  }

  function impressionsHandler() {
    var products = getVisibleProducts();
    if(products.length > 0) {
      window.ecommerce.impressions(products);
    }
  }

  $(window).scroll(function() {

    if (waiting) {
      return;
    }
    waiting = true;

    // clear previous scheduled endScrollHandle
    clearTimeout(endScrollHandle);

    impressionsHandler();

    setTimeout(function () {
      waiting = false;
    }, 500);

    endScrollHandle = setTimeout(function () {
      impressionsHandler();
    }, 700);
  });
})();
