/**
 * Created by dev on 05.06.17.
 */
if( argilla.event ) {

  // search
  argilla.event.attachEventHandler('load.page', function(data) {
    if( data.route === 'search/index' ) {
      var text = location.search.split('text=')[1];
      tagManager.search(text);
    }
  });

  //order
  argilla.event.attachEventHandler('collection.add', function(data) {
    if( data.collection == 'basket' ) {
      tagManager.addToBasketFromAllPage();
      $('.js-ecommerce-add[data-id="' + data.id + '"]').trigger('ecommerce.add');

      switch(argilla.getParam('route')) {
        case "product/one":
          tagManager.addToBasketFromCard();
          break;

        case "product/section":
        case "product/type":
          tagManager.addToBasketFromCatalog();
        break;

        case "favorite/index":
          tagManager.addToBasketFromFavorite();
        break;

        default:
          tagManager.addToBasketFromOtherPage();
      }
    }
  });

  argilla.event.attachEventHandler('collection.remove', function(data) {
    if (data.collection == 'basket') {
      $('.js-ecommerce-remove[data-id="' + data.id + '"]').trigger('ecommerce.remove');
    }
  });

  argilla.event.attachEventHandler('click', function(button) {
    if( button === 'product.share' ) {
      tagManager.shareFromCard();
    }
  });

  argilla.event.attachEventHandler('click', function(button) {
    if( button === 'order.submit' ) {
      tagManager.submitOrder();
    }
  });

  argilla.event.attachEventHandler('purchase', function($order) {
    var deferred = argilla.createCallbackDeferred();
    tagManager.ecommerce.purchase($order);
    deferred.resolve();
  });

  //popups
  argilla.event.attachEventHandler('show.popup', function(data) {
    if( data.selector === 'popup-fastOrderbasket' ) {
      tagManager.showOneClick();
    }
  });

  argilla.event.attachEventHandler('hide.popup', function(data) {
    if( data.selector === 'popup-fastOrderbasket' ) {
      tagManager.closeOneClick();
    }
  });

  argilla.event.attachEventHandler('response.success', function(data) {
    if( data.form === 'FastOrder' ) {
      tagManager.submitOneClick();
    }
  });

  argilla.event.attachEventHandler('show.popup', function(data) {
    if( data.selector === '#callback-popup' ) {
      tagManager.showCallback();
    }
  });

  argilla.event.attachEventHandler('hide.popup', function(data) {
    if( data.selector === '#callback-popup' ) {
      tagManager.closeCallback();
    }
  });

  argilla.event.attachEventHandler('response.success', function(data) {
    if( data.form === 'Callback' ) {
      tagManager.submitCallback();
    }
  });

  argilla.event.attachEventHandler('show.popup', function(data) {
    if( data.selector === 'popup-requestProductbasket' ) {
      tagManager.showProductRequest();
    }
  });

  argilla.event.attachEventHandler('response.success', function(data) {
    if( data.form === 'RequestProduct' ) {
      tagManager.submitProductRequest();
    }
  });

  //favorite
  argilla.event.attachEventHandler('collection.add', function(data) {
    if( data.collection == 'favorite' ) {
      tagManager.addToFavorite();
    }
  });
}